/**
 * Bind logout click
 */
$(function(){
	$("#logout").bind("click", function(){
		Cookies.set("token", null);
		window.location = "/login";
	});




	$('[data-toggle="tooltip"]').tooltip()

});